html,
body {
  height: auto !important;
  background-color: rgb(234, 234, 234) !important;
}

.ui.container {
  height: 100vh !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.ui.inverted.top.fixed.menu {
  background-image: linear-gradient(
    135deg,
    rgb(24, 42, 115) 0%,
    rgb(33, 138, 174) 69%,
    rgb(32, 167, 172) 89%
  ) !important;
}
